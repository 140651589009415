.form-container{
    --prince: #2c3e50;
    --card-lord: #ffffff;
    ----prince: #fff;
    --card---prince: #f9f9f9;
    
    --active:#1fd1ec;
    --light:#9ca3af;

    --rainbo: #f87171;
    --grass:#bada55;

    --shadow-lg:0px 0px 6px rgba(95, 20, 20, 0.16);
    --shadow-focus: 0px 0px 6px rgba(32, 211, 238,0.5);
    --shadow-active:0px 0px 5px rgba(32, 211, 238,0.5);
}


/* Query Cleaner */
.owl-form-cleaner{
    flex-wrap:  wrap;
    display:  flex;
    gap: 10px;
    box-shadow: inset 0px 0px 8px #f8717155;
    border-radius: 0.5rem;
    margin-top: 10px;
    padding: 10px;
}

/* Container */
.form-container {
     display: flex;
    flex-direction: column;
    box-shadow:var(--shadow-lg);
    gap: 10px;
    padding: 10px;
}

/* Section */
.form-section {
    padding: 6px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;    
    align-items: center;
    border-radius: 0.5rem;
    overflow: auto;
    /* box-shadow:var(--shadow-lg);
    background-color: var(--card-lord); */
    /* border:  1px solid #ccc; */
    /* border-bottom: 2px solid #ccc; */
}

.clear-icon{
    color: rgb(252, 165, 165);
    font-size: 24px;
    /* background-color: var(--card-lord); */
    font-family: "'BoutrosMBCDinkum Medium'";
    font-weight: 300;
    left: 10px;
    padding-right: 3px;
    padding-top: 2px;
    z-index: 10;
    position: absolute;
    cursor: pointer;
}
.clear-icon.hide{
    display: none;
}

.owl-toggle-button{
    display: flex;
    margin-inline: 8px;
    align-items: center;
    flex-direction: row;
    margin-block:auto;
}

.owl-toggleed-bg{
    cursor: pointer;
    padding: 2px;
    display: flex;
    margin: 0 4px;
    border-radius: 50px;
    transition: all 0.2s ease-in-out;
    background-color: var(--card-lord);
    box-shadow: var(--shadow-lg);
    padding-left: 22px;
}
.owl-toggleed-bg span{
    padding: 10px;
    border-radius: 24px;
    background-color: var(--card-lord);
    box-shadow: var(--shadow-lg);
}

.owl-toggleed {
    padding-right: 22px;
    padding-left: 2px;
     background-color:var(--grass); /*#F87171; */
}

/* Selector */
.owl-options-container {
    /* color: var(--card-lord); */
    background-color: var(--card---prince);
    flex-direction: row;
    display: flex;
    gap: 10px;
    padding:5px;
    border-radius: 0.5rem;
    box-shadow: var(--shadow-lg);
    overflow-x: auto;
    transition: all 200ms ease-out;
}

.owl-options-container::-webkit-scrollbar { height: 4px;width: 4px;}
.owl-options-container::-webkit-scrollbar-track {background-color: #e4e4e4;border-radius: 100px;margin: 10px;}
.owl-options-container::-webkit-scrollbar-track {background-color: #e4e4e4;}
.owl-options-container::-webkit-scrollbar-thumb {  border-radius: 6px;}
.owl-options-container:hover::-webkit-scrollbar-thumb {background-color: #3f3c4d55;}

.owl-options-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    padding-inline:0.5rem;
}
.owl-options-title {
    font-family: "'BoutrosMBCDinkum Medium'";
    font-weight: bold;
}

.owl-options {
    box-shadow: var(--shadow-lg);
    border-radius: 0.5rem;
    padding: 0.5rem;
    outline: none;
    border: none;
    font-family: "'BoutrosMBCDinkum Medium'";
    font-weight: bold;
}

/* Date */
.owl-label-container{
    flex-direction: row;
    align-items: center;
    max-width: 160px;
    display:  flex ;
    flex-grow: 1;
    gap: 5px;
    padding: 9px;
    border-radius: 0.5rem;
    background-color: var(--card-lord);
    justify-content: space-between;
    box-shadow: var(--shadow-lg);
}

.owl-label{
    color: var(--light);
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 300;
    margin-block: -20px;
    font-family: "'BoutrosMBCDinkum Medium'";
}

.owl-date{
    outline: none;
    border: none;
    font-family: "'BoutrosMBCDinkum Medium'";
    font-weight: bold;
    color: var(--prince);
}

/* Button */
.owl-button-conatiner{
    position: relative;
}

.owl-button{
    font-family: "'BoutrosMBCDinkum Medium'";
    background-color: var(--card-lord);
    color: var(--rainbo);
    border-radius: 0.5rem;
    padding: 4px 16px;
    font-size: 12px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: var(--shadow-lg);
}


.owl-button-conatiner{
    position: relative;
}

.owl-button-options{
    color: var(--text);
    background-color: var(--card-lord);
    border-radius: 0.5rem;
    padding: 10px 16px;
    font-size: 12px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: var(--shadow-lg);
    min-width: max-content;
    font-family: "'BoutrosMBCDinkum Medium'";
    font-weight: bold;
}

.selected{
    box-shadow: var(--shadow-active) !important;
}


.owl-popup-container{
    position: absolute;
    inset: 0;
    background-color:#2c3e5022;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(1px);
    /* transition: all 0.1s ease-in-out; */
    animation:  bluer 0.1s;
}
@keyframes bluer {
    0% {
        background-color:#2c3e5000;
        backdrop-filter: blur(0px);
    }
    100% {
        background-color:#2c3e5022;
        backdrop-filter: blur(1px);
    }
}


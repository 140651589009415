 
.item-card{
    background-color: var(--prim);
    padding: var(--lg);
    display: flex;
    flex-direction: column;
    color: var(--shark);
    box-shadow: var(--shadow-xl);
    border-radius: var(--xl);
    gap: var(--lg);
    /* justify-content: space-between; */
    font-size: 13px;
}

#items-container{
    display: grid;
    gap: 10px;
    padding: 10px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.quantity-input{
    animation: quntity-input-frame 300ms ease-in-out;
    max-width: 80px;
    padding: 4px 10px !important;
 }
@keyframes quntity-input-frame{
    0%{
        height: 0;
    }
    100%{
        height: 100%;
    }
}

.quantity-input [is="progress"]{
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
    background-color: var(--goat);
    position: relative;
    overflow: hidden;
}


.packages{
    display:inline-flex;
    /* gap: 10px; */
    /* padding: 10px; */
    background-color: var(--prince);
    border-radius: var(--md);
    padding: 4px;
    text-align: center;
    color: var(--shark);
    font-size: 10px;
}
[is=""]{
    cursor: pointer;
    padding: 5px 10px ;
    flex-grow: 0;
    margin-inline: 10px;
}
[is="selected"]{
    background-color: var(--dragon);
    border-radius: var(--md);
    padding: 5px 10px ;
    flex-grow: 1;
    color: var(--white);
    transition: all 0.2s ease-out;
}

.morabaa-icon { fill:#fff;}

.morabaa-icon .bg {
    fill: #1f2937;
}

.morabaa-icon .curve {
    fill: #f87171;
}
P{font-family: "'BoutrosMBCDinkum Medium'";font-weight: bold;}

.undo{
    color: var(--dragon);
    cursor: pointer;
    font-size: 12px;
    padding-inline: 10px;
    display: inline-block;
}

.border-top{
    border-top: 1px solid var(--goat);
    padding-top: var(--sm);
}

.loading {
    position: relative;
    padding-left: 40px;
    transition: all 0.2s;
}

.loading #loader-target {
    padding: 6px;
    border-radius: 50px;
    border: 5px #22c55e55 solid;
    border-left-color: #22c55e;
    animation: loadingFrame 500ms infinite linear;
    position: absolute;
    left: 8px;
    margin-top: -3px;
}

@keyframes loadingFrame {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
